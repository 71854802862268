import React, { Component } from 'react';
import * as styles from './Index.module.scss';
import ChinesePolicy from '../../components/policy/chinese';

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <div className={styles.page}>
        <ChinesePolicy />
      </div>
    );
  }
}

export default Questions;
