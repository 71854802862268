import React from 'react';
import { Anchor, Row, Col, Divider, Table, Tooltip } from 'antd';
import * as styles from './policy.module.scss';
const { Link } = Anchor;

const ChinesePolicy = () => {
    const dataSource = [
        {
            key: '1',
            name: '北京北森云计算股份有限公司',
            objective: '外购SAAS软件产品，进行人力资源管理',
            way: '使用、存储',
            type: 'HR人力资源管理系统供应商，可获取员工所有信息',
        },
        {
            key: '2',
            name: '广州市八方锦程人力资源有限公司',
            objective: '对候选人进行背调',
            way: '收集、使用、存储',
            type: '姓名、出生日期、身份证件号码、电话号码、电子邮箱、教育经历、毕业证件、工作经历等',
        },
        {
            key: '3',
            name: '全景求是管理顾问（北京）有限公司',
            objective: '对候选人进行背调',
            way: '收集、使用、存储',
            type: '姓名、出生日期、身份证件号码、电话号码、电子邮箱、教育经历、毕业证件、工作经历等',
        },
        {
            key: '4',
            name: '北京飞书科技有限公司',
            objective: '进行人力资源管理',
            way: '收集、使用、存储',
            type: '姓名、出生日期、电话号码、电子邮箱、教育经历、毕业证件、工作经历等',
        },
    ];

    const columns = [
        {
            title: '第三方的名称与联系方式',
            dataIndex: 'name',
            key: 'name',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: '处理目的',
            dataIndex: 'objective',
            key: 'objective',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}><a>{text}</a></Tooltip>
        },
        {
            title: '处理方式',
            dataIndex: 'way',
            key: 'way',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: '个人信息的种类',
            dataIndex: 'type',
            key: 'type',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
    ];
    return (
        <div className={styles.home}>
            <Row>
                <Col span={6}>
                    <Anchor targetOffset={16} style={{ paddingTop: '16px', }}>
                        {/* <Link className={styles.anchorLink} href='#policyEn0' title='RECRUITMENT INFORMATION NOTICE' /> */}
                        <Link className={styles.anchorLink} href='#policyZh1' title='一、相关定义' />
                        <Link className={styles.anchorLink} href='#policyZh2' title='二、我们如何收集和使用个人信息' />
                        <Link className={styles.anchorLink} href='#policyZh3' title='三、我们如何使用Cookie或同类技术' />
                        <Link className={styles.anchorLink} href='#policyZh4' title='四、我们如何处理您的个人信息' />
                        <Link className={styles.anchorLink} href='#policyZh5' title='五、我们如何存储个人信息' />
                        <Link className={styles.anchorLink} href='#policyZh6' title='六、我们如何保护您的个人信息' />
                        <Link className={styles.anchorLink} href='#policyZh7' title='七、我们如何管理您的个人信息' />
                        <Link className={styles.anchorLink} href='#policyZh8' title='八、未成年人保护' />
                        <Link className={styles.anchorLink} href='#policyZh9' title='九、本政策的修订和更新' />
                        <Link className={styles.anchorLink} href='#policyZh10' title='十、如何联系我们' />
                    </Anchor>
                </Col>
                <Col span={18} style={{ padding: '0 20px 0 20px', marginTop: '26px', }}>
                    <div id="policyZh0" className={styles.policyTitle}>安克招聘网隐私权政策</div>
                    <div className="policy-describe">
                        <p>最新版本发布日期：2023年6 月26日</p>
                        <p>本政策适用于安克创新科技股份有限公司、其子公司和关联公司（统称“安克”、“本公司”、或“我们”）通过安克招聘门户网站（“安克招聘网”）处理（包括收集、使用、共享、转让、公开等）的来自对本公司感兴趣的求职者或潜在求职者的（“您”或“您的”）个人信息（定义参见下文）。</p>
                        <p>我们非常重视您的隐私，为了更好的保护您的个人信息，建议您仔细阅读更新后的《安克招聘网隐私权政策》（“本政策”），尤其是以<span className={styles.fontMax}>黑色加粗</span>的条款，了解我们将会收集您的哪些信息，以及我们将会如何使用这些信息，确保您充分理解和同意之后再开始使用。当您同意本政策时，即表示您已同意我们按照本政策处理您的相关信息。除非您已阅读并接受本政策所有条款，否则您不应使用安克招聘网。</p>
                        <p>本政策适用于包括但不限于您在使用安克招聘网过程中主动提供或由于服务需要而产生的个人信息。<span className={styles.fontMax}>但本政策不适用于第三方对您的个人信息的使用，我们鼓励您在向该第三方提供信息前熟悉相应的隐私权政策。</span></p>
                        <p>如您对本政策条款有任何异议或疑问，您可通过本政策第十条“如何联系我们”中公布的联系方式与我们沟通。</p>
                        <Divider />
                        <p>本政策将帮助您了解以下内容：</p>
                        <p>一、相关定义</p>
                        <p>二、我们如何收集和使用个人信息</p>
                        <p>三、我们如何使用Cookie或同类技术</p>
                        <p>四、我们如何处理您的个人信息</p>
                        <p>五、我们如何存储个人信息</p>
                        <p>六、我们如何保护您的个人信息</p>
                        <p>七、我们如何管理您的个人信息</p>
                        <p>八、未成年人保护</p>
                        <p>九、本政策的修订和更新</p>
                        <p>十、如何联系我们</p>
                    </div>
                    <div id="policyZh1" className={styles.policyTips}>
                        <div className={styles.title}>一、相关定义</div>
                        <p className={styles.subTitle}>1. 个人信息</p>
                        <p>个人信息：个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。在本政策中包括：姓名、年龄、性别、照片、电子邮箱、电话号码、学历、学位、教育经历、工作经历等。</p>
                        <p>敏感个人信息：指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。在本政策中包括：<span className={styles.fontMax}>身份证或护照证件号</span>等。</p>
                        <p className={styles.subTitle}>2. 匿名化处理</p>
                        <p>通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原。</p>
                        <p className={styles.subTitle}>3. 去标识化处理</p>
                        <p>是指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或关联个人信息主体的过程。</p>
                        <p className={styles.subTitle}>4. Cookie</p>
                        <p>Cookie 是发送至或访问自您 Web 浏览器或计算机硬盘的一种小文本文件。Cookie 通常包含产生 Cookie 的域名（互联网位置）、Cookie 的“有效期”（即到期日）以及随机生成的一串唯一编号或类似标识符。Cookie 还可能包含有关设备的信息，例如：用户设置、浏览历史以及使用本服务时所进行的活动。</p>
                    </div>
                    <div id="policyZh2" className={styles.policyTips}>
                        <div className={styles.title}>二、我们如何收集和使用个人信息</div>
                        <p>我们依据法律法规以及遵循正当、合法、必要、公开、透明、“最小化”的原则处理您在使用服务过程中主动提供或由于服务需要而产生的个人信息，并告知您相关个人信息的使用目的和范围。<span className={styles.fontMax}>如果我们欲将您的个人信息用于本政策未载明的其他用途，或基于特定目的将收集而来的信息用于其他目的，我们会及时以合理的方式向您告知，并在使用前再次征得您的同意。</span></p>
                        <p>除此之外，您理解并同意，我们希望提供给您的服务是完善的，所以我们会不断改进我们的服务，包括技术，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人信息或变更个人信息使用目的或方式。<span className={styles.fontMax}>如果某一需要收集您的个人信息的功能或服务未能在本政策中予以说明的，我们会通过更新本政策、电子邮件、手机短信、页面提示、弹窗、网站公告等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您明示同意后收集。</span>在此过程中，如果您对相关事宜有任何问题，可以通过本政策第十条“如何联系我们”中公布的联系方式与我们沟通，我们会尽快为您作出解答。</p>
                        <p>我们会出于以下目的处理您以下类型的个人信息：</p>
                        <p className={styles.subTitle}>1. 用户注册及登录</p>
                        <p>当您在安克招聘网创建账户时，您需要提供（1）手机号码，电子邮箱；并创建（2）用户名和（3）密码。</p>
                        <p>您在使用安克招聘网时，为维护您的基本资料，改善您的服务体验，需要您选择填写及/或完善服务必要的信息，可能包括您的电子邮箱、手机号码等。创建账户后，我们会将您的个人登录信息发送给您。我们可以利用此个人信息管理您的账户，（例如根据您的要求验证您的访问或更改密码）。</p>
                        <p><span className={styles.fontMax}>您也可以使用第三方账号（抖音）登录，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与安克招聘网账号绑定，使您可以直接登录并使用相关服务。获取上述信息是为了记住您作为安克招聘网用户的登录身份，并将您的第三方账号与安克招聘网账号进行绑定。如您需要取消上述第三方账号与安克招聘网账号的绑定，您可以通过本政策第十条“如何联系我们”中提供的方式与我们联系或者本政策第七条中提供的方式管理您的个人信息。</span></p>
                        <p className={styles.subTitle}>2. 职位筛选与搜索功能</p>
                        <p>为分析我们服务的使用情况以及提升用户使用的体验，当您使用安克招聘网的职位筛选与搜索功能时，我们可能会收集您的搜索关键字信息。</p>
                        <p className={styles.subTitle}>3. 职位申请与邀请</p>
                        <p>当您在网页申请意向职位时，或者通过电子邮件或其他方式与我们联系时，我们需要您提供：您的身份证或护照证件号、简历、姓名、年龄、性别、手机号码、邮箱地址、教育经历、工作经历等信息。此外，您还可以根据自身情况选择填写实习经历、项目经历、作品、获奖情况、语言能力、自我评价和社交账号来完善您的信息。这些信息可能包含敏感个人信息，亦属于完成相关职位申请所必须的信息，您可以选择不予提供，但您可能无法正常使用我们的相关服务。</p>
                        <p>为评估您是否符合我们对相关职位的预期与需求，我们可能会使用您的上述个人信息进行职位、工作能力以及相关职业资格等方面的评估。我们可能使用您提供的电子邮箱和手机号码与您联系，以便于与您交流您的申请或工作兴趣，您可以选择不予提供，但是公司无法联系您的情况下，可能无法保证您参与相关活动。例如，我们可能会向您提供有关工作申请的状态更新，告知新的职位空缺或邀请您参加招聘活动，例如电话或现场面试。</p>
                        <p className={styles.subTitle}>4. 帮助您参加校园招聘宣讲会</p>
                        <p>当您选择参加我们或第三方合作伙伴举办的有关活动时（例如校园招聘宣讲会），可能需要您提供姓名、手机号码、电子邮箱、所属学校、所在城市等信息。我们收集这些信息用以确定场次人数、与您取得联系以及帮助我们后续进行职位评估，您可以选择不予提供，但您可能无法参与相关活动。</p>
                        <p className={styles.subTitle}>5. 访问我们的办公地点</p>
                        <p>当您访问我们的办公地点时，为了保护您和其他用户、服务及财产安全，我们可能会收集您的姓名、电子邮箱、电话号码、身份证或护照证件号、个人影像等 。这些信息包含敏感个人信息，亦属于完成相关面试活动所必须的信息，您可以选择不予提供，但您可能无法正常参与我们的招聘流程。</p>
                        <p className={styles.subTitle}>6. 评估以及背景调查</p>
                        <p>我们会处理您的相关信息，以并评估您是否是合适的职位候选人。因此，我们可能会在招聘数据库中包含您的信息，用于管理安克候选人的甄选和招聘流程。请注意，我们可能会从其他来源获取您的个人信息，例如您的推荐人或介绍人等。我们也可能会要求您根据安克政策和相关法律法规完成背景调查，并将您的信息分享给第三方背景调查公司。</p>
                        <p className={styles.subTitle}>7. 为您提供安全保障</p>
                        <p>为提高您使用我们及第三方合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或相关协议规则的情况，我们可能会收集您的设备信息、日志信息，并可能使用或整合您的个人信息、设备信息、日志信息以及第三方合作伙伴取得您授权或依据法律法规共享的信息，来综合判断您账户风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
                        <p className={styles.subTitle}>8. 改进我们的服务</p>
                        <p>为更好地了解您对安克招聘网服务的意见和建议，以提高我们的服务质量，我们可能在您同意的情况下通过您的电子邮箱、手机号码以及其他您同意并提供的联系方式或通过安克招聘网官方平台向您发送/提供问卷、对您进行访谈等。在您填写问卷/接受访谈的过程中，根据调查问卷/访谈类型的不同，您需要提供您不同类别的个人信息。如果您不希望提供相关个人信息，您可拒绝填写问卷/接受访谈。</p>
                        <p>在您使用我们服务过程中，为识别账户异常状态、向您提供浏览、搜索等基本服务，保障服务正常运行，优化服务体验，我们可能会直接或间接地收集、存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括日志信息与设备信息。</p>
                    </div>
                    <div id="policyZh3" className={styles.policyTips}>
                        <div className={styles.title}>三、我们如何使用Cookie或同类技术</div>
                        <p>我们或我们的第三方合作伙伴目前暂未通过Cookie获取和使用您的个人信息。</p>
                        <p>要了解有关 Cookie 和网络信标的更多信息，请访问 www.allaboutcookies.org。</p>
                    </div>
                    <div id="policyZh4" className={styles.policyTips}>
                        <div className={styles.title}>四、我们如何处理您的个人信息</div>
                        <p className={styles.subTitle}>1. 共同处理和委托处理</p>
                        <p>您充分知晓并同意，在为您提供安克招聘网相应服务时，我们会在以下情形中共同处理和委托处理您的个人信息，超出以下情形时，我们会通过第九条所述通知方式另行向您征求您的授权同意：</p>
                        <p>1）<span className={styles.fontMax}>共同处理：</span>在获取您的单独同意时或者在符合本政策第四条中第四节“授权同意的例外”的情况下，我们可能与帮助我们运营、分析、改进、定制、支持及营销的第三方合作伙伴共同处理您的信息。</p>
                        <p>2）<span className={styles.fontMax}>委托处理：</span>安克招聘网部分业务功能中由外部供应商提供，我们委托处理个人信息的第三方合作伙伴只能接触到其履行职责所需信息，且我们会对其委托处理活动进行监督。</p>
                        <p>例如，为了完成聘用所必须的相关调查，我们可能会将您的个人信息共享给第三方背景调查公司，并由其将调查结果反馈给我们（在适用法律允许的情况下，这些信息可能包括您的身份证或护照证件号、学历、学位、教育经历、工作经历，是否有违法犯罪记录等）。请您知悉，第三方背景调查公司提供给我们的调查结果可能会包含您此前未提供给我们的个人信息，我们会对此类个人信息采取严格保护措施，若发现此类个人信息与招聘无关，我们会尽快删除相关个人信息。我们也会使用第三方合作伙伴北京北森云计算有限公司提供的HR SaaS服务。</p>
                        <p>我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托处理和共同处理您的信息。我们会对第三方合作伙伴个人信息安全环境进行合理审查，并与其签署严格的个人信息处理协议，保证其只能接触到其履行职责所需信息，并要求其对您的信息采取足够的保护措施，严格遵守相关法律法规与监管要求。</p>
                        <p><span className={styles.fontMax}>如果我们与第三方合作伙伴关于处理目的、处理方式的协议变更并实质影响了原来的处理目的和处理方式，我们会重新取得您的授权同意。如果您选择取消授权，我们会停止该权限所对应的服务。</span></p>
                        <p className={styles.subTitle}>2. 转让</p>
                        <p>在获取您的单独同意时或者在符合本政策<span className={styles.fontMax}>第四条中第四节“授权同意的例外”的情况下</span>，我们可能会将您的个人信息转让给其他公司、组织或个人。如果发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本政策的约束。如果本政策中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。</p>
                        <p className={styles.subTitle}>3. 公开</p>
                        <p>在获取您的单独同意时或者在符合本政策<span className={styles.fontMax}>第四条中第四节“授权同意的例外”的情况下</span>，我们可能会在采取符合业界标准的安全防护措施的前提下公开个人信息。对于公开的您的个人信息，我们会在收到公开申请后第一时间且审慎审查其正当性、合理性、合法性，并在公开时和公开后采取本政策第六条所述措施对其进行保护。</p>
                        <p className={styles.subTitle}>4. 授权同意的例外</p>
                        <p>请您知悉，根据法律法规的规定，在下述情况下，处理您的个人信息无需事先征得您的授权同意：</p>
                        <p>- 与国家安全、国防安全直接相关的；</p>
                        <p>- 为应对突发公共卫生事件的；</p>
                        <p>- 与犯罪侦查、起诉、审判和判决执行等直接相关的；或根据法律法规的要求、行政机关或公检法等有权机关的要求的；</p>
                        <p>- 紧急情况下为保护自然人的生命健康和财产安全所必需的， 如出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
                        <p>- 个人信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</p>
                        <p>- 根据与您签订和履行相关协议或其他书面文件所必需的；</p>
                        <p>- 为履行法定职责或者法定义务所必需。</p>
                    </div>
                    <div id="policyZh5" className={styles.policyTips}>
                        <div className={styles.title}>五、我们如何存储个人信息</div>
                        <p className={styles.subTitle}>1. 储存地点</p>
                        <p>我们依照法律法规的规定，将在中华人民共和国境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。如果我们向境外传输，我们将会遵循相关国家规定，单独向您明确告知（包括个人信息出境的目的、接收方、使用方式与范围、使用内容等），并征得您的授权同意或者在其他法律允许的情况下转移个人信息，我们会确保个人信息接收方有充足的个人信息保护能力来保护您的个人信息。</p>
                        <p className={styles.subTitle}>2. 储存期限</p>
                        <p>我们在为您提供满足服务目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册及相应功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续保存您的手机号码，以向您正常提供该功能、保障您的账户和系统安全。</p>
                        <p>我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：</p>
                        <p>- 完成与您相关的服务记录，以应对您可能的查询或投诉；</p>
                        <p>- 保证我们为您提供服务的安全和质量；</p>
                        <p>- 您是否同意更长的留存期间；</p>
                        <p>- 是否存在保留期限的其他特别约定；及</p>
                        <p>- 法律规定的必要期限。</p>
                        <p>在超出上述存储期限后，除非符合<span className={styles.fontMax}>第四条中第四节“授权同意的例外”的情况</span>，我们会对您的个人信息进行删除或匿名化处理。</p>
                    </div>
                    <div id="policyZh6" className={styles.policyTips}>
                        <div className={styles.title}>六、我们如何保护您的个人信息</div>
                        <p>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</p>
                        <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以电子邮件、手机短信、页面提示、弹窗、网站公告等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                    </div>
                    <div id="policyZh7" className={styles.policyTips}>
                        <div className={styles.title}>七、我们如何管理您的个人信息</div>
                        <p className={styles.subTitle}>1. 访问与更新</p>
                        <p>我们鼓励您更新和修改您的信息以使其更准确有效。我们将采取适当的操作设置、指引或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的信息或使用安克招聘网时提供的其他信息。如您想访问、更新和更正您的部分信息，请登录安克招聘网进行相关操作，或者通过本政策中载明的联系方式联系我们进行处理。</p>
                        <p>此外，您也可以登录个人帐户，并访问您的帐户设置页，以查看和更改您的帐户资料信息。<span className={styles.fontMax}>除法律法规另有规定，当您更正您的个人信息时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。</span></p>
                        <p className={styles.subTitle}>2. 改变您授权同意的范围或撤回您的授权</p>
                        <p>您可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。请您理解，我们的服务需要一些基本的个人信息才能完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权所进行的信息的收集、处理与使用。</p>
                        <p className={styles.subTitle}>3. 注销及删除</p>
                        <p>我们为您提供注册账户的注销及删除功能。您可以通过向hr-recruit@anker-in.com提交请求注销或删除您的账户。</p>
                        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
                        <p>- 如果我们处理个人信息的行为违反法律法规；</p>
                        <p>- 如果我们收集、使用您的个人信息，却未征得您的同意；</p>
                        <p>- 如果我们处理个人信息的行为违反了与您的约定；</p>
                        <p>- 如果您不再使用我们的服务，或您注销了账户；</p>
                        <p>- 如果我们不再为您提供服务。</p>
                        <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的单独授权。<span className={styles.fontMax}>当您或我们协助您删除相关信息后，因为适用的法律法规和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并不会再对其进行进一步处理，直到备份可以清除或实现匿名。</span></p>
                        <p className={styles.subTitle}>4. 响应您的上述请求</p>
                        <p>我们非常重视您的每一个请求。与此同时，您理解并同意，在第四条中第四节“授权同意的例外”的情形下，我们有可能将无法及时响应您的请求。</p>
                        <p className={styles.subTitle}>5. 获取您的个人信息副本</p>
                        <p>您有权获取您的个人信息副本，您可以通过登录个人帐户，并访问您的帐户设置页如“我的简历”部分，以获取您的个人信息副本。</p>
                    </div>
                    <div id="policyZh8" className={styles.policyTips}>
                        <div className={styles.title}>八、未成年人保护</div>
                        <p>安克招聘网的服务仅面向成年人使用，我们不会在明知的情况下收集、处理未成年人个人信息。</p>
                        <p>未成年人使用我们的服务前应取得其监护人的同意。如您为未成年人，在使用我们的服务前，应在监护人监护、指导下共同阅读本政策且应在监护人明确同意和指导下使用我们的服务、提交个人信息。我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律法规允许、监护人明确同意的情况下处理未成年人的个人信息。</p>
                        <p>若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，您可以通过本政策第十条“如何联系我们”中公示的联系方式与我们沟通解决。如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会尽快删除相关个人信息。</p>
                    </div>
                    <div id="policyZh9" className={styles.policyTips}>
                        <div className={styles.title}>九、本政策的修订和更新</div>
                        <p>为了给您提供更好的服务，我们可能会根据安克招聘网服务的更新情况及法律法规的相关要求适时修改本政策的条款，并在本页面发布对本政策的任何修改，该等修改构成本政策的一部分。对于重大变更，我们还会提供更为显著的通知（包括电子邮件、手机短信、页面提示、弹窗、网站公告或其它方式）。本政策所指的重大变更包括但不限于：</p>
                        <p className={styles.subTitle}>1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
                        <p className={styles.subTitle}>2. 我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
                        <p className={styles.subTitle}>3. 个人信息共享、转让或公开披露的主要对象发生重大变化；</p>
                        <p className={styles.subTitle}>4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
                        <p className={styles.subTitle}>5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；</p>
                        <p className={styles.subTitle}>6. 个人信息安全影响评估报告表明存在高风险时；</p>
                        <p className={styles.subTitle}>7. 其他重要的或可能严重影响您的个人权益的情况发生。</p>
                        <p>您如果不同意该等变更，可以选择停止使用安克招聘网服务；如您仍然继续使用安克招聘网服务的，即表示您已充分阅读、理解并同意受经修订的本政策的约束。我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用安克招聘网服务时都查阅本政策。</p>
                        <p>我们可能在必需时（例如当我们由于系统维护而暂停、变更、终止某一项服务时）发出与服务有关的公告。如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送推广短信，或在网页端进行设置，不再接收我们推送的消息；但我们依法律规定或服务协议约定发送消息的情形除外。</p>
                    </div>
                    <div id="policyZh10" className={styles.policyTips}>
                        <div className={styles.title}>十、如何联系我们</div>
                        <p>如您对本政策的内容或使用我们的服务时遇到的与隐私保护相关的事宜有任何疑问或进行咨询或投诉时，您均可以通过如下任一方式与我们取得联系： </p>
                        <p className={styles.subTitle}>1. 您可以向我们开通的hr-recruit@anker-in.com邮箱发送邮件与我们联系</p>
                        <p className={styles.subTitle}>2. 您可以拨打我们的客服电话400 0550 036 （周一至周五 9:00-17:30）与我们联系。</p>
                        <p>我们会在收到您的意见及建议后，并在验证您的用户身份后的15个工作日内尽快向您回复。</p>
                    </div>
                    <Divider />
                    <div className={styles.tableTitle}>安克招聘网个人信息共同处理情况说明</div>
                    <Table rowClassName={styles.tableRow} className={styles.table} bordered dataSource={dataSource} columns={columns} pagination={false} />
                </Col>
            </Row>
        </div>
    );
};

export default ChinesePolicy;
